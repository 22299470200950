
import { defineComponent } from 'vue-demi';
import { mapGetters } from 'vuex';
import Pagination from "../common/table/modules/Pagination.vue";
import { ROLES_ENUM } from '../../utils/enums';
import { dynamicsObject } from '@/interfaces';
import Actions from './modules/actions.vue';
import Experts from './modules/experts.vue';

export default defineComponent({
  name: 'AnalyticsMain',
  data() {
    return {
      common: {} as dynamicsObject,
      query: {
        limit: 20,
        page: 1,
        search: ''
      },
      totalActions: 0,
      average: {} as dynamicsObject,
      commonList: [
        { id: 'questionnaires', name: 'Кол-во сданных работ моими студентами:' },
        { id: 'completedQuestionnaires', name: 'Кол-во проверенных мною работ:' },
      ],
      averageList: [
        { id: 'august', name: 'Среднее время проверки работ в августе:' },
        { id: 'september', name: 'Среднее время проверки работ в сентябре:' },
        { id: 'october', name: 'Среднее время проверки работ в октябре:' },
        { id: 'november', name: 'Среднее время проверки работ в ноябре:' },
        { id: 'december', name: 'Среднее время проверки работ в декабре:' },
      ],
      actions: [],
      experts: [],
      selected: 'actions',
      access: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    staff() {
      const user = this.user as dynamicsObject;
      return user && (user.accesses.includes(ROLES_ENUM.EXPERT) || user.role === ROLES_ENUM.SUPPORT || user.role === ROLES_ENUM.OWNER);
    }
  },
  mounted() {
    if (this.user.role !== ROLES_ENUM.EXPERT || this.user.accesses.includes(ROLES_ENUM.EXPERT)) {
      this.access = true;
      this.getCommon();
      this.getExperts();
      this.getCommonList();
    } else {
      this.getCommonExpert();
    }
    this.getAverage();
    this.getActions();
  },
  components: {
    Actions,
    Experts,
    Pagination
  },
  methods: {
    onSearch() {
      this.getActions();
    },
    async getCommon() {
      const result = await this.API.analytics.getCommon();
      this.common = result.data;
    },
    async getCommonExpert() {
      const result = await this.API.analytics.getCommonExpert();
      this.common = result.data;
    },
    async getAverage() {
      const result = await this.API.analytics.getAverage();
      this.average = result.data;
    },
    async getActions() {
      const result = await this.API.analytics.getActions({ params: this.query });
      this.actions = result.data;
      this.totalActions = result.total;
    },
    async getExperts() {
      const result = await this.API.analytics.getExperts();
      this.experts = result.data;
    },
    getCommonList() {
      this.commonList = [
        { id: 'students', name: 'Всего студентов:' },
        { id: 'questionnairesLength', name: 'Всего работ:' },
        { id: 'questionnaires', name: 'Кол-во сданных работ студентами:' },
        { id: 'completedQuestionnaires', name: 'Кол-во проверенных работ экспертами:' },
      ];
    },
    page(page: number) {
      this.query.page = page;
      this.getActions();
    },
  },
});
