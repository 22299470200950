
import { PropType, defineComponent } from 'vue-demi';
import { dynamicsObject } from '@/interfaces';

export default defineComponent({
  name: "AnalyticsExperts",
  props: {
    experts: {
      type: Array as PropType<Array<dynamicsObject>>,
      required: true,
    },
  },
});
