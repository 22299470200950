
import { PropType, defineComponent } from 'vue-demi';
import dateFilter from '@/filters/date.filter';
import { Action } from '@/interfaces/dto/action';

export default defineComponent({
  name: 'AnalyticsActions',
  data() {
    return {
      variables: ['owner', 'createdAt', 'message'] as Array<keyof typeof Action.Props>,
    }
  },
  methods: {
    dateFilter,
  },
  props: {
    actions: {
      type: Array as PropType<Array<Action.Dto>>,
      required: true
    }
  }
});
